/* eslint-disable no-unused-expressions */
import moment from 'moment';
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import intl from 'react-intl-universal';

import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import ScrollBar from '../../components/ScrollBar';

import FroalaEditorComponent from '../../config/FroalaEditorComponent';
import { Alert } from '../../components/Alert';
import Breadcrumb from '../../components/Breadcrumbs';

import Axios from '../../config/Axios';
import Page from '../default-v2/Page';
import RequisiteShow from './RequisiteShow';
import { can, checkModule } from '../../config/Permissions';
import LinkDocument from './auditEvidences/LinkDocument';
import CaptureMedia from './auditEvidences/CaptureMedia';
import UploadFiles from './auditEvidences/UploadFiles';
import AddComment from './auditEvidences/AddComment';

import Audit from './Audit';
import './styles/audit.scss';
import './styles/auditindex.scss';
import './styles/loading.scss';
import Filter from './components/Filter';
import CardHeader from '../default-v2/CardHeader';

import managementImg from '../../assets/img/management.svg';
import url from '../../config/Url';

import { textSearchFormat } from '../norm/RequisiteIndex';
import { oLocalStorage } from '../../config/EncodedLocalStorage';
import { fnDecodeHTMLEntities } from '../../config/Formatter';

const REQUISITE_NOT_FINISHED_VALUE = 33;

class AuditIndex extends Component {
  constructor(oProps) {
    super(oProps);
    const { match } = this.props;
    this.state = {
      bLoading: true,
      nEvidenceSelected: null,
      cEvidenceType: '',
      aEvidences: [],
      bEvidenceLoading: false,
      conformities: false,
      oAudit: [],
      bActualMenuFolder: false,
      nAuditId: match.params.nAuditId,
      aPages: [{ cName: 'DashboardAudit.titulo', cLink: '/audit-management' }],
      aRequisites: [],
      statusSave: '',
      filterSearch: '',
      nInitialRequisiteID: oProps.location.state?.nInitialRequisiteID ?? '',
      initialRequisiteRef: React.createRef(),
      bFirstScroll: true,
      aFiltredRequisites: [],
      oNorm: [],
      cEditorContent: '',
      bCanUserCreateEdit: false,
      aAuditRequisites: [],
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  msgSucesso = msg => {
    const { setAlert } = this.props;
    this.hideAlert();

    setAlert('success', msg);
  };

  scrollIntoInitialRequisite = () => {
    const { nInitialRequisiteID, initialRequisiteRef, bFirstScroll } = this.state;
    const initialRequisiteScrollInterval = setInterval(() => {
      if (nInitialRequisiteID && initialRequisiteRef.current) {
        setTimeout(() => {
          if (bFirstScroll) {
            initialRequisiteRef.current.scrollIntoView();
            clearInterval(initialRequisiteScrollInterval);
            this.setState({
              bFirstScroll: false
            });
          }
        }, 600);
      }
    }, 600);
  };

  componentDidMount = () => {
    const { nAuditId } = this.state;
    const { setAlert, history } = this.props;
    checkModule(history, 'audit-management');

    document.addEventListener('mousedown', this.handleClickOutside);
    Promise.all([
      Axios.get(`audit/${nAuditId}`).then(res => {
        this.setState(
          {
            oAudit: res.data.oAudit,
            cEditorContent: res.data.oAudit.audi_cfinalnote
          },
          () => {
            this.setState(
              {
                canUserAccess: this.checkIfUserCanAccess(),
                bCanUserCreateEdit: this.checkIfUserCanCreateEdit()
              }
            );
          }
        );
      }),
      Axios.get(`audit/requisites/${nAuditId}`).then(res => {
        this.setState(
          {
            aRequisites: res.data,
          },
          () => {
            this.scrollIntoInitialRequisite();
          }
        );
      }),
      Axios.get(`audit-requisites/${nAuditId}`).then(res => {
        this.setState(
          {
            aAuditRequisites: res.data,
          }
        );
      })
    ])
      .then(() => {
        this.setState({
          bLoading: false
        });
        this.applyFilter();
        // this.downloadAttachment();
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };

  getAuditRequest = () => {
    const { nAuditId } = this.state;
    const { setAlert } = this.props;

    document.addEventListener('mousedown', this.handleClickOutside);
    Promise.all([
      Axios.get(`audit-requisites/${nAuditId}`).then(res => {
        this.setState(
          {
          },
          () => {
            this.setState(
              {
                aAuditRequisites: res.data,
              }
            );
          }
        );
      })
    ])
      .then(() => {
        this.applyFilter();
      })
      .catch(oError => {
        setAlert('error', oError.msgErrors);
      })
      .finally(() => {
        this.setState({
          bLoading: false
        });
      });
  };

  downloadAttachment = () => {
    const { match, setAlert } = this.props;
    const { nEvidenceId } = match.params;
    const bIsSet = nEvidenceId !== undefined && nEvidenceId !== null;
    if (!bIsSet) return null;

    Axios.get(`audit-evidence/attachment/${nEvidenceId}`)
      .then(oResponse => {
        const elNewLink = document.createElement('a');
        elNewLink.href = oResponse.data.cAttachment;
        elNewLink.download = oResponse.data.cDocName;
        elNewLink.click();
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  setWrapperRef(cNode) {
    this.wrapperRef = cNode;
  }

  showDeleteAuditEvidence = aEvid => {
    const { bCanUserCreateEdit } = this.state;

    if (bCanUserCreateEdit) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            showCancel
            confirmBtnText={intl.get('confirmar')}
            cancelBtnText={intl.get('cancelar')}
            cancelBtnBsStyle="danger"
            title={intl.get('AuditIndex.confirmacao_remocao_evidencia', {
              cEvidence:
                aEvid.arev_ctitle || `'${fnDecodeHTMLEntities(aEvid.arh_cauditorcomment
                  .replace(/&nbsp;/g, ' ')
                  .replace(/(<([^>]+)>)/gi, '')
                  .substr(0, 20))}'`
            })}
            onConfirm={() => this.deleteAuditEvidence(aEvid)}
            onCancel={this.hideAlert}
          />
        )
      });
    }
  };

  deleteAuditEvidence = aEvid => {
    if (aEvid.arev_nid) {
      Axios.delete(`audit-evidence/${aEvid.arev_nid}`)
        .then(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title={intl.get('removida')}
                onConfirm={() => {
                  this.hideAlert();
                }}
              >
                {intl.get('AuditIndex.exclusao_sucesso')}
              </SweetAlert>
            )
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title="Ops!" onConfirm={this.hideAlert}>
                {intl.get('falhou')}
                <p>{oError.msgErrors}</p>
              </SweetAlert>
            )
          });
        });
    } else {
      Axios.delete(`audit-requisite-history/${aEvid.arh_nid}`)
        .then(() => {
          this.setState({
            rcmpAlert: (
              <SweetAlert
                success
                title={intl.get('removida')}
                onConfirm={() => {
                  this.hideAlert();
                }}
              >
                {intl.get('AuditIndex.exclusao_sucesso')}
              </SweetAlert>
            )
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlert: (
              <SweetAlert error title="Ops!" onConfirm={this.hideAlert}>
                {intl.get('falhou')}
                <p>{oError.msgErrors}</p>
              </SweetAlert>
            )
          });
        });
    }
  };

  hideAlert = () => {
    this.componentDidMount();
    this.setState({
      rcmpAlert: null,
      alert: null,
      nEvidenceSelected: null,
    });
  };

  menuOpen = () => {
    this.setState(oCurrentState => ({
      bActualMenuFolder: !oCurrentState.bActualMenuFolder
    }));
  };

  loadChild = evt => {
    this.elNodeLi = evt.target;

    if (evt.target.classList.contains('insidespan') === false) {
      if (evt.target.closest('li').classList.contains('open')) {
        evt.target.closest('li').classList.toggle('open');
        if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
          evt.target
            .closest('li')
            .getElementsByClassName('subLevel')[0]
            .classList.toggle('open');
        }
      } else if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
        this.elNodeLi.closest('li').classList.toggle('open');
        const elHoShow = evt.target.closest('li').getElementsByClassName('subLevel')[0];
        elHoShow.classList.toggle('open');
      }
    }
  };

  closeContextMenu = (e, wrapperRef) => {
    const element = wrapperRef;
    if (element.getElementsByClassName('changeState')[0]) {
      element.getElementsByClassName('changeState')[0].style = 'display: none';
    }
  };

  openContextMenu = e => {
    const { bCanUserCreateEdit } = this.state;
    if (bCanUserCreateEdit && e.target.closest('div').getElementsByClassName('changeState')[0]) {
      e.target.closest('div').getElementsByClassName('changeState')[0].style = 'display: block';
    }
  };

  getEvidences = (oRequisite, cEvidenceType) => {
    const { aEvidences, nEvidenceSelected, aAuditRequisites } = this.state;
    const nReqNid = oRequisite.req_nid;

    this.setState({
      cEvidenceType
    });
    if (aEvidences && nEvidenceSelected === nReqNid) {
      return;
    }

    this.setState({
      cEvidenceType,
      nEvidenceSelected: nReqNid,
      bEvidenceLoading: true
    });

    Axios.get(`audit/evidences/${aAuditRequisites[oRequisite.req_nid].arq_nid}`).then(data => {
      this.setState({
        aEvidences: data.data
      });
    }).catch(() => {
      this.setState({
        rcmpAlert: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {intl.get('warning_loading_evidence')}
          </Alert>
        )
      });
    }).finally(() => {
      this.setState({
        bEvidenceLoading: false
      });
    });
  }

  openMenuEvidence = e => {
    if (e.target.closest('div').getElementsByClassName('menuContextEvid')[0]) {
      e.target.closest('div').getElementsByClassName('menuContextEvid')[0].style = 'display: block';
    }
  };

  closeMenuEvidence = (e, wrapperRef) => {
    const element = wrapperRef;
    if (element.getElementsByClassName('menuContextEvid')[0]) {
      element.getElementsByClassName('menuContextEvid')[0].style = 'display: none';
    }
  };

  getFeaturedClassName = (requisite, aAuditRequisites) => {
    let featured = false;

    if (requisite.req_nid_parent) {
      featured = aAuditRequisites[requisite.req_nid].stat_nid !== REQUISITE_NOT_FINISHED_VALUE;
    } else {
      featured = requisite.recursive_children_requisite_with_audit.length
        ? this.canHighlightFirstLevelRequisite(requisite, REQUISITE_NOT_FINISHED_VALUE, aAuditRequisites)
        : aAuditRequisites[requisite.req_nid].stat_nid !== REQUISITE_NOT_FINISHED_VALUE;
    }
    return featured ? 'all-finished' : 'non-finished';
  };

  canHighlightFirstLevelRequisite = (requisiteToValidate, incompleteValueCondition, aAuditRequisites) => {
    let statuses = [];
    const statusesFromChildren = getRequisiteStatusInDepth(requisiteToValidate);

    return !JSON.stringify(statusesFromChildren).includes(incompleteValueCondition);

    function getRequisiteStatusInDepth(requisiteToSearch) {
      if (requisiteToSearch.recursive_children_requisite_with_audit.length) {
        requisiteToSearch.recursive_children_requisite_with_audit.forEach(req => {
          statuses = [...statuses, aAuditRequisites[requisiteToValidate.req_nid].stat_nid];
          return getRequisiteStatusInDepth(req);
        });
      }
      return statuses;
    }
  };

  checkRecursiveInitialRequisiteParent = oRequisite => {
    const { nInitialRequisiteID } = this.state;

    if (oRequisite.req_nid === nInitialRequisiteID) {
      return true;
    }

    if (oRequisite.recursive_children_requisite_with_audit) {
      return oRequisite.recursive_children_requisite_with_audit.filter(oRecursiveRequisite =>
        this.expandInitialRequisiteParent(oRecursiveRequisite)
      ).length;
    }

    return false;
  };

  expandInitialRequisiteParent = oRequisite => {
    if (this.checkRecursiveInitialRequisiteParent(oRequisite)) {
      oRequisite.open = true;
      return true;
    }
    return false;
  };

  renderChilds = (oRequisite, subLevel) => {
    const { nInitialRequisiteID, bCanUserCreateEdit, aAuditRequisites } = this.state;

    return (
      <>
        {oRequisite.recursive_children_requisite_with_audit &&
          Object.keys(oRequisite.recursive_children_requisite_with_audit).length > 0 && (
            <div className={`subLevel ${oRequisite.open ? 'open' : ''}`}>
              <ul ref={this[`${oRequisite.req_nid}_ref`]}>
                {Object.keys(oRequisite.recursive_children_requisite_with_audit).map((cKey, i) => {

                  return aAuditRequisites[oRequisite.recursive_children_requisite_with_audit[cKey].req_nid] && (
                    <li
                      itemID={oRequisite.recursive_children_requisite_with_audit[cKey].req_nid}
                      key={`req${oRequisite.recursive_children_requisite_with_audit[cKey].req_nid}`}
                      ref={
                        oRequisite.recursive_children_requisite_with_audit[cKey].req_nid === nInitialRequisiteID
                          ? this.state.initialRequisiteRef
                          : null
                      }
                      className={`${oRequisite.recursive_children_requisite_with_audit[cKey].open ? 'open' : ''
                        } ${this.getFeaturedClassName(oRequisite.recursive_children_requisite_with_audit[cKey], aAuditRequisites)}`}
                      role="button"
                      tabIndex={i}
                      onKeyPress={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite_with_audit[cKey]);
                      }}
                      onClick={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite_with_audit[cKey]);
                      }}
                    >
                      <div
                        className={`row-table ${oRequisite.recursive_children_requisite_with_audit[cKey].req_nid === nInitialRequisiteID ? 'focus' : ''
                          }`}
                      >
                        {this.renderRequisiteTitleDescription(oRequisite.recursive_children_requisite_with_audit[cKey], subLevel)}

                        <div className="optionsContainerTable">
                          <span
                            onClick={e => {
                              e.stopPropagation();
                              this.openContextMenu(e);
                            }}
                            className={`status insidespan status-${aAuditRequisites[oRequisite.recursive_children_requisite_with_audit[cKey].req_nid].stat_nid}`}
                          >
                            {this.getStatusName(aAuditRequisites[oRequisite.recursive_children_requisite_with_audit[cKey].req_nid].stat_nid) ??
                              ''}{' '}
                            {bCanUserCreateEdit && (
                              <span>
                                <i className="icon icon-next" />
                              </span>
                            )}
                            {this.renderContextMenu(aAuditRequisites[oRequisite.recursive_children_requisite_with_audit[cKey].req_nid])}
                          </span>
                          <span className="responsible">
                            {aAuditRequisites[oRequisite.recursive_children_requisite_with_audit[cKey].req_nid].user.user_cname}
                          </span>

                          <span className="optionsHover mobile480-hide">
                            {this.renderRequisiteMenu(oRequisite.recursive_children_requisite_with_audit[cKey])}
                          </span>
                        </div>
                      </div>
                      {this.renderChilds(oRequisite.recursive_children_requisite_with_audit[cKey], subLevel + 1)}
                    </li>
                  );
                }
                )}
              </ul>
            </div>
          )}
      </>
    );
  };

  openRequisite = (evt, oRequisite) => {
    evt.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
          onCancel={this.hideAlert}
        >
          <div className="head-modal">
            {oRequisite.req_cname}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <RequisiteShow
              nRequisiteId={oRequisite.req_nid}
              oRequisite={oRequisite}
              setAlert={setAlert}
              onSuccess={cMsg => {
                this.setMessage(cMsg, 'success');
              }}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  setMessage = (cMsg, cType) => {
    let cMessageType = cType;
    if (typeof cMessageType === 'undefined') {
      cMessageType = 'success';
    }
    this.hideAlert();

    this.setState({
      rcmpAlertMsg: (
        <Alert type={cMessageType} isOpen onCloseAlert={() => { }}>
          {cMsg}
        </Alert>
      )
    });
  };

  changeStatus = async (evt, nRequisiteId, nStatusId) => {
    const { nAuditId } = this.state;
    evt.persist();
    evt.preventDefault();
    evt.target.closest('span').classList.add('load');
    evt.target.closest('div').style.display = 'none';

    Axios.post('/audit-requisite/change-status', {
      audi_nid: nAuditId,
      req_nid: nRequisiteId,
      stat_nid: nStatusId
    })
      .then(() => {
        this.applyFilter();
        this.getAuditRequest();
      })
      .catch(oError => {
        this.setState({
          rcmpAlert: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.msgErrors}
            </Alert>
          )
        });
      });

    // if (evt.target.closest('span') !== null) evt.target.closest('span').classList.remove('load');
  };

  requisiteDescription = aSections => {
    const oSectionDescription = aSections.find(oSection => oSection.rs_cdescription);
    return oSectionDescription ? oSectionDescription.rs_cdescription : '';
  };

  handleClickOutside(evt) {
    if (this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
      this.setState({
        bActualMenuFolder: false
      });
    }
  }

  renderRequisiteTitleDescription = (oRequisite, subLevel) => (
    <span>
      <div className="requisite-item-list-title">
        <div>
          <a onClick={e => this.openRequisite(e, oRequisite)} href="#view" className="view-bt">
            <i className="icon icon-eye" />
          </a>
          {(oRequisite.recursive_children_requisite_with_audit.length > 0) && (
            <span>
              <i className="icon icon-next" />
            </span>
          )}
        </div>
        <div
          className={this.requisiteDescription(oRequisite.requisite_section) === '' ? 'inc-width' : ''}
          style={{ paddingLeft: subLevel * 15 }}
        >
          {oRequisite.req_cname}
        </div>
        {this.requisiteDescription(oRequisite.requisite_section) !== '' && (
          <div className="requisite-item-list-description">
            {this.requisiteDescription(oRequisite.requisite_section)}
          </div>
        )}
      </div>
    </span>
  );

  openAddEvid = (oRequisite, cType) => {
    const { bCanUserCreateEdit, aAuditRequisites, canUserAccess } = this.state;
    if (bCanUserCreateEdit || canUserAccess) {
      this.setState({
        rcmpAlert: (
          <SweetAlert
            customClass={`modal-edit lg ScrollBar disabled-overflow ${cType === 'newdoc' ? 'force-overflow' : ''
              }`}
            title=""
            onConfirm={this.hideAlert}
            showConfirm={false}
          >
            <div className="head-modal">
              {cType === 'document' && intl.get('vincular_documento')}
              {(cType === 'comment' || cType === 'comment1') && intl.get('novo_comentario')}
              {(cType === 'photo' || cType === 'attachment') && intl.get('AuditIndex.anexar_arquivos')}

              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>

            {cType === 'document' && (
              <ScrollBar>
                <LinkDocument
                  onSuccess={cMsg => {
                    this.setMessage(cMsg, 'success');
                  }}
                  onError={cMsg => {
                    this.setMessage(cMsg, 'error');
                  }}
                  closeModal={this.hideAlert}
                  oAudit={aAuditRequisites[oRequisite.req_nid]}
                  oRequisite={oRequisite}
                  bNewDocument
                />
              </ScrollBar>
            )}

            {cType === 'attachment' && (
              <UploadFiles
                oRequisite={oRequisite}
                oAudit={aAuditRequisites[oRequisite.req_nid]}
                onSuccess={cMsg => {
                  this.setMessage(cMsg, 'success');
                }}
                closeModal={this.hideAlert}
              />
            )}
            {cType === 'photo' && (
              <CaptureMedia
                oRequisite={oRequisite}
                oAudit={aAuditRequisites[oRequisite.req_nid]}
                aEvidences={oRequisite.audit?.evidences ?? []}
                onSuccess={cMsg => {
                  this.setMessage(cMsg, 'success');
                }}
                closeModal={this.hideAlert}
              />
            )}

            {cType === 'comment' && (
              <AddComment
                oRequisite={oRequisite}
                oAudit={aAuditRequisites[oRequisite.req_nid]}
                isAuditEvidence
                onSuccess={cMsg => {
                  this.setMessage(cMsg, 'success');
                }}
                closeModal={this.hideAlert}
              />
            )}

            {cType === 'comment1' && (
              <>
                <AddComment
                  oRequisite={oRequisite}
                  oAudit={aAuditRequisites[oRequisite.req_nid]}
                  isCommentEvidence
                  onSuccess={cMsg => {
                    this.setMessage(cMsg, 'success');
                  }}
                  closeModal={this.hideAlert}
                />
              </>
            )}
          </SweetAlert>
        )
      });
    }
  };

  renderRequisiteMenu = oRequisite => {
    const { canUserAccess, bCanUserCreateEdit, nEvidenceSelected, bEvidenceLoading, aAuditRequisites } = this.state;
    return (
      <>
        {oRequisite.req_etitletype != 'T' && (
          <>
            <div>
              <a
                tooltiptext={intl.get('documento')}
                href="/#addDocument"
                className="insidespan"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.openMenuEvidence(e);
                  aAuditRequisites[oRequisite.req_nid].arq_ndocumentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_ndocumentcount > 0
                    ? this.getEvidences(oRequisite, 'document')
                    : this.openAddEvid(oRequisite, 'document');
                }}
              >
                {bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid ? <div className="spinner" /> : <i className="icon icon-file-empty" />}
                {aAuditRequisites[oRequisite.req_nid].arq_ndocumentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_ndocumentcount > 0 && (
                  <b className="counter">{aAuditRequisites[oRequisite.req_nid].arq_ndocumentcount}</b>
                )}
              </a>
              {!bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid && this.renderContextMenuEvidece(oRequisite, 'document')}
            </div>
            <div>
              <a
                tooltiptext={intl.get('AuditIndex.anexo')}
                href="/#addAttachment"
                className="insidespan"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.openMenuEvidence(e);
                  aAuditRequisites[oRequisite.req_nid].arq_nattachmentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_nattachmentcount > 0
                    ? this.getEvidences(oRequisite, 'attachment')
                    : this.openAddEvid(oRequisite, 'attachment');
                }}
              >
                {bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid ? <div className="spinner" /> : <i className="icon icon-attachment" />}
                {aAuditRequisites[oRequisite.req_nid].arq_nattachmentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_nattachmentcount > 0 && (
                  <b className="counter">{aAuditRequisites[oRequisite.req_nid].arq_nattachmentcount}</b>
                )}
              </a>
              {!bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid && (this.renderContextMenuEvidece(oRequisite, 'attachment'))}
            </div>
            <div>
              <a
                tooltiptext={intl.get('AuditIndex.foto_video')}
                href="/#addPhoto"
                className="insidespan"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.openMenuEvidence(e);
                  aAuditRequisites[oRequisite.req_nid].arq_nphotocount !== null && aAuditRequisites[oRequisite.req_nid].arq_nphotocount > 0
                    ? this.getEvidences(oRequisite, 'photo')
                    : this.openAddEvid(oRequisite, 'photo');
                }}
              >
                {bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid ? <div className="spinner" /> : <i className="icon icon-camera" />}
                {aAuditRequisites[oRequisite.req_nid].arq_nphotocount !== null && aAuditRequisites[oRequisite.req_nid].arq_nphotocount > 0 && (
                  <b className="counter">{aAuditRequisites[oRequisite.req_nid].arq_nphotocount}</b>
                )}
              </a>
              {!bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid && this.renderContextMenuEvidece(oRequisite, 'photo')}
            </div>

            <div>
              <a
                tooltiptext={intl.get('AuditIndex.texto')}
                href="/#addComment"
                className="insidespan"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  this.openMenuEvidence(e);
                  aAuditRequisites[oRequisite.req_nid].arq_ncommentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_ncommentcount > 0
                    ? this.getEvidences(oRequisite, 'comment')
                    : this.openAddEvid(oRequisite, 'comment');
                }}
              >
                {bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid ? <div className="spinner" /> : <i className="icon icon-bubble" />}
                {aAuditRequisites[oRequisite.req_nid].arq_ncommentcount !== null && aAuditRequisites[oRequisite.req_nid].arq_ncommentcount > 0 && (
                  <b className="counter">{aAuditRequisites[oRequisite.req_nid].arq_ncommentcount}</b>
                )}
              </a>
              {!bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid && this.renderContextMenuEvidece(oRequisite, 'comment')}
            </div>
          </>
        )}
        <div>
          <a
            title={intl.get('AuditIndex.comentario')}
            href="/#openEditRequisite"
            className="insidespan speak-audit"
            onClick={e => {
              e.stopPropagation();
              e.preventDefault();
              this.openMenuEvidence(e);
              canUserAccess &&
                bCanUserCreateEdit &&
                (aAuditRequisites[oRequisite.req_nid].requisite_history.length !== 0
                  ? this.getEvidences(oRequisite, 'comment1')
                  : this.openAddEvid(oRequisite, 'comment1'));

              canUserAccess &&
                !bCanUserCreateEdit &&
                (aAuditRequisites[oRequisite.req_nid].requisite_history.length !== 0
                  ? this.getEvidences(oRequisite, 'comment1')
                  : this.openAddEvid(oRequisite, 'comment1'));
            }}
          >
            {bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid ? <div className="spinner" /> : <i className="icon icon-speak" />}
            {aAuditRequisites[oRequisite.req_nid].requisite_history.length !== 0 && (
              <b className="counter">{aAuditRequisites[oRequisite.req_nid].requisite_history.length}</b>
            )}
          </a>
          {!bEvidenceLoading && nEvidenceSelected === oRequisite.req_nid && this.renderContextMenuEvidece(oRequisite, 'comment1')}
        </div>
      </>
    );
  };

  handleChangeCheckbox = e => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  handleChange = e => {
    const { aRequisites } = this.state;
    this.setState(
      {
        filtredRequisites: aRequisites,
        [e.target.name]: e.target.value
      },
      () => {
        this.applyFilter();
      }
    );
  };

  handleChangeFilter = (e, oFilter) => {
    this.setState(
      {
        filterSearch: oFilter.cText
      },
      () => {
        this.applyFilter();
      }
    );
  };

  editEvidence = (oEvidence, oRequisite) => {
    const { aAuditRequisites } = this.state;
    if (oEvidence.arev_etype === 'comment' || oEvidence.arev_etype === 'comment1') {
      this.setState({
        rcmpAlert: (
          <SweetAlert customClass="modal-edit lg disabled-overflow" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {intl.get('RequisiteShow.edit_comment')}
              <span
                role="button"
                tabIndex="0"
                aria-labelledby="head-modal"
                onKeyPress={this.hideAlert}
                onClick={this.hideAlert}
                className="close"
                href=""
              />
            </div>
            {oEvidence.arev_etype === 'comment' && (
              <AddComment
                oEvidence={oEvidence}
                isAuditEvidence
                oRequisite={oRequisite}
                oAudit={aAuditRequisites[oRequisite.req_nid]}
                onSuccess={cMsg => {
                  this.setMessage(cMsg, 'success');
                }}
                closeModal={this.hideAlert}
              />
            )}
            {oEvidence.arev_etype === 'comment1' && (
              <AddComment
                oEvidence={oEvidence}
                isAuditEvidence
                isCommentEvidence
                oRequisite={oRequisite}
                oAudit={aAuditRequisites[oRequisite.req_nid]}
                onSuccess={cMsg => {
                  this.setMessage(cMsg, 'success');
                }}
                closeModal={this.hideAlert}
              />
            )}
          </SweetAlert>
        )
      });
    }
  };

  openDocument = oEvidence => {
    if (oEvidence?.arev_etype === 'folder') {
      window.open(`/folder/${oEvidence.fldr_nid}`, 'folder', 'noopener', 'noreferrer');
      return;
    }

    Axios.get(`/document/version-history/${oEvidence.doc_nid}`)
      .then(response => {
        if (oEvidence?.arev_etype === 'document') {
          window.open(`/document/detail/${response.data.nVerId}`, 'document', 'noopener');
        }
      })
      .catch(error => {
        this.setState({
          alert: (
            <SweetAlert error title="Ops!" onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.msgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  renderContextMenuEvidece = (oRequisite, type) => {
    const { bCanUserCreateEdit, canUserAccess, oAudit, aEvidences, nEvidenceSelected, cEvidenceType, aAuditRequisites } = this.state;

    const userIsResponsible = oAudit.audit_responsibles && oAudit.audit_responsibles.length > 0 && Number(localStorage.getItem('nUserId')) === oAudit.audit_responsibles[0].user.user_nid;

    return (
      <OutsideHandlerClick key={`menu-${oRequisite.req_nid}`} handleClickOutside={this.closeMenuEvidence}>
        <ul className="menuContextEvid" style={{ display: cEvidenceType === type && nEvidenceSelected === oRequisite.req_nid ? 'block' : 'none' }}>
          {type === 'comment1' && (
            <div key="comment1-context-menu">
              {aAuditRequisites[oRequisite.req_nid].requisite_history.map((oAutitAudicomment, auditorCommentIndex) => (
                <li
                  key={`auditor-comment-${auditorCommentIndex}`}
                  onClick={e => {
                    e.stopPropagation();
                    this.editEvidence(
                      {
                        ...oAutitAudicomment,
                        arev_etype: 'comment1'
                      },
                      oRequisite
                    );
                  }}
                  title={oAutitAudicomment.arh_cauditorcomment.replace(/&nbsp;/g, ' ').replace(/(<([^>]+)>)/gi, '')}
                >
                  {fnDecodeHTMLEntities(oAutitAudicomment.arh_cauditorcomment
                    .replace(/&nbsp;/g, ' ')
                    .replace(/(<([^>]+)>)/gi, '')
                    .replace('Powered by Froala Editor', ''))}
                  {bCanUserCreateEdit && (
                    <span
                      onClick={e => {
                        e.stopPropagation();
                        this.showDeleteAuditEvidence(oAutitAudicomment);
                      }}
                      className="linkRemove"
                    >
                      -
                    </span>
                  )}
                </li>
              ))}
            </div>
          )}
          {(type === 'photo' || type === 'attachment') && (
            <div key="media-context-menu">
              {aEvidences
                .filter(attachmentEvidence => attachmentEvidence.arev_etype === type)
                .map((attachmentEvidence, attachmentEvidenceIndex) => (
                  <li
                    key={`req-${oRequisite.req_nid}-attachment-evidence-${attachmentEvidenceIndex}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.downloadFile(e, attachmentEvidence, attachmentEvidence.arev_nid);
                    }}
                    title={attachmentEvidence.arev_ctitle}
                  >
                    {attachmentEvidence.arev_ctitle}{' '}
                    {bCanUserCreateEdit && (
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          this.showDeleteAuditEvidence(attachmentEvidence);
                        }}
                        className="linkRemove"
                      >
                        -
                      </span>
                    )}
                  </li>
                ))}
            </div>
          )}
          {type === 'document' && (
            <div key="document-context-menu">
              {aEvidences
                .filter(arevEvid => ['document', 'folder'].includes(arevEvid.arev_etype))
                .map((arevEvid, arevEvidIndex) => (
                  <li
                    key={`req-${oRequisite.req_nid}-document-evidence-${arevEvidIndex}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.openDocument(arevEvid);
                    }}
                    title={arevEvid.arev_ctitle}
                  >
                    {arevEvid.arev_ctitle}{' '}
                    {bCanUserCreateEdit && (
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          this.showDeleteAuditEvidence(arevEvid);
                        }}
                        className="linkRemove"
                      >
                        -
                      </span>
                    )}
                  </li>
                ))}
            </div>
          )}
          {type === 'comment' && (
            <div key="comment-context-menu">
              {aEvidences
                .filter(arevEvid => arevEvid.arev_etype === type)
                .map((arevEvid, arevEvidIndex) => (
                  <li
                    key={`req-${oRequisite.req_nid}-comment-evidence-${arevEvidIndex}`}
                    onClick={e => {
                      e.stopPropagation();
                      this.editEvidence(arevEvid, oRequisite);
                    }}
                    title={arevEvid.arev_ctitle}
                  >
                    {arevEvid.arev_ctitle}{' '}
                    {bCanUserCreateEdit && (
                      <span
                        onClick={e => {
                          e.stopPropagation();
                          this.showDeleteAuditEvidence(arevEvid);
                        }}
                        className="linkRemove"
                      >
                        -
                      </span>
                    )}
                  </li>
                ))}
            </div>
          )}

          {userIsResponsible ? (
            <li onClick={() => this.openAddEvid(oRequisite, type)} className="addLink">
              {intl.get('AuditIndex.adicionar')} <span className="add">+</span>
            </li>
          ) : bCanUserCreateEdit || (canUserAccess && type !== 'comment1' && type !== 'comment') ? (
            <li onClick={() => this.openAddEvid(oRequisite, type)} className="addLink">
              {intl.get('AuditIndex.adicionar')} <span className="add">+</span>
            </li>
          ) : oAudit.audit_auditors && oAudit.audit_auditors.length > 0 && oAudit.audit_auditors[0].user.user_cname ? (
            <li onClick={() => this.openAddEvid(oRequisite, type)} className="addLink">
              {intl.get('AuditIndex.adicionar')} <span className="add">+</span>
            </li>
          ) : (
            ''
          )}
        </ul>
      </OutsideHandlerClick>
    );
  };

  renderContextMenu = oRequisite => {
    const { bCanUserCreateEdit } = this.state;
    if (!bCanUserCreateEdit) {
      return null;
    }

    return (
      <OutsideHandlerClick handleClickOutside={this.closeContextMenu}>
        <div className="changeState auditStatus" id="changeState">
          {oRequisite.stat_nid !== 33 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 33)} href="#">
              {intl.get('AuditIndex.selecione')}
            </a>
          )}
          {oRequisite.stat_nid !== 34 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 34)} className="finished" href="#">
              {intl.get('AuditIndex.atendido')}
            </a>
          )}
          {oRequisite.stat_nid !== 35 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 35)} className="pending" href="#">
              {intl.get('AuditIndex.nao_atendido')}
            </a>
          )}
          {oRequisite.stat_nid !== 36 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 36)} className="n-partially" href="#">
              {intl.get('AuditIndex.atendido_parcialmente')}
            </a>
          )}
          {oRequisite.stat_nid !== 37 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 37)} className="n-applied" href="#">
              {intl.get('AuditIndex.nao_se_aplica')}
            </a>
          )}
          {oRequisite.stat_nid !== 38 && (
            <a onClick={e => this.changeStatus(e, oRequisite.req_nid, 38)} className="n-audited" href="#">
              {intl.get('AuditIndex.nao_auditado')}
            </a>
          )}
        </div>
      </OutsideHandlerClick>
    );
  };

  getStatusName = statusId => {
    const status = {
      33: intl.get('AuditIndex.selecione'),
      34: intl.get('AuditIndex.atendido'),
      35: intl.get('AuditIndex.nao_atendido'),
      36: intl.get('AuditIndex.atendido_parcialmente'),
      37: intl.get('AuditIndex.nao_se_aplica'),
      38: intl.get('AuditIndex.nao_auditado')
    };
    return status[statusId];
  };

  filterByName = requisite => {
    const { filterSearch } = this.state;
    return textSearchFormat(requisite.req_cname).includes(textSearchFormat(String(filterSearch)));
  };

  filterByStatus = requisite => {
    const { filterSearch, aAuditRequisites } = this.state;
    const status = this.getStatusName(aAuditRequisites[requisite.req_nid].stat_nid);

    return (
      requisite.req_etitletype !== 'T' &&
      status &&
      textSearchFormat(status).includes(textSearchFormat(String(filterSearch)))
    );
  };

  filterByAuditor = requisite => {
    const { filterSearch, aAuditRequisites } = this.state;

    return textSearchFormat(aAuditRequisites[requisite.req_nid].user?.user_cname ?? '').includes(textSearchFormat(String(filterSearch)));
  };

  filterByDate = requisite => {
    const { filterSearch } = this.state;

    return moment
      .parseZone(requisite.req_dedited)
      .tz('America/Sao_Paulo')
      .format('DD/MM/YYYY')
      .toLowerCase()
      .includes(String(filterSearch).toLowerCase());
  };

  checkAllFilters = requisite => {
    requisite.open = false;

    if (
      this.filterByName(requisite) ||
      this.filterByStatus(requisite) ||
      this.filterByAuditor(requisite) ||
      this.filterByDate(requisite)
    ) {
      requisite.open = true;
      return Boolean(
        (requisite.recursive_children_requisite_with_audit = requisite.recursive_children_requisite_with_audit.filter(recursive_rec =>
          this.checkAllFilters(recursive_rec)
        ))
      );
    }

    if (requisite.recursive_children_requisite_with_audit) {
      requisite.open = true;
      return (requisite.recursive_children_requisite_with_audit = requisite.recursive_children_requisite_with_audit.filter(
        recursive_rec => this.checkAllFilters(recursive_rec)
      )).length;
    }

    return false;
  };

  applyFilter = () => {
    const { filterSearch, aRequisites } = this.state;

    const aFilteredNow = JSON.parse(JSON.stringify(aRequisites)).filter(oReq => this.checkAllFilters(oReq));

    this.setState({
      aFiltredRequisites: aFilteredNow.length && filterSearch ? aFilteredNow : []
    });

    setTimeout(() => { }, 1);
  };

  downloadFile = (e, oAttachmentEvidence, nId) => {
    e.preventDefault();
    const { setAlert } = this.props;

    this.setState({ bLoading: false });

    Axios.get(`audit-evidence/attachment/${nId}`)
      .then(oResult => {
        fetch(oResult.data.cAttachment)
          .then(response => response.blob())
          .then(fBlob => {
            const oLink = document.createElement("a");
            oLink.href = URL.createObjectURL(fBlob);
            document.body.appendChild(oLink);
            oLink.download = oAttachmentEvidence.arev_ctitle;
            oLink.click();
            document.body.removeChild(oLink);
          }).catch(oError => {
            this.setState({ bLoading: false });
            setAlert('error', oError.msgErrors);
          });
      })
      .catch(oError => {
        this.setState({ bLoading: false });
        setAlert('error', oError.msgErrors);
      });
  };

  changeStateNorm = (e, { norm_nid, bisactive }) => {
    e.preventDefault();

    Axios.get(`/norm/change-status/${norm_nid}`)
      .then(response => {
        const { oNorm } = this.state;
        oNorm.norm_bisactive = oNorm.norm_bisactive === 1 ? 0 : 1;

        this.setState({
          oNorm
        });
      })
      .catch(error => {
        this.setState({
          oLoadNorm: '',
          alert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.msgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  showDeleteAudit = (e, { audi_nid, norm }) => {
    e.preventDefault();

    this.setState({
      alert: (
        <SweetAlert
          showCancel
          confirmBtnText={intl.get('confirmar')}
          cancelBtnText={intl.get('cancelar')}
          cancelBtnBsStyle="danger"
          title={intl.get('DashboardAudit.titulo_excluir_auditoria', { cNormInitials: norm.norm_cinitials })}
          onConfirm={() => this.deleteAudit(audi_nid, norm.norm_cinitials)}
          onCancel={this.hideAlert}
        >
          {intl.get('DashboardAudit.delete1')}
          <p> {intl.get('DashboardAudit.delete3')} </p>
        </SweetAlert>
      )
    });
  };

  deleteAudit = (audi_nid, normName) => {
    const { history } = this.props;
    Axios.delete(`audit/${audi_nid}`)
      .then(() => {
        history.push('/audit-management');
      })
      .catch(error => {
        this.setState({
          alert: (
            <SweetAlert error title={intl.get('opa')} onConfirm={this.hideAlert}>
              {intl.get('falhou')}
              <p>{error.msgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  editAudit = (e, oAudit) => {
    e.preventDefault();
    this.setState({
      alert: (
        <SweetAlert
          customClass="modal-edit md disabled-overflow ScrollBar"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            {intl.get('AuditIndex.editar_auditoria')}
            <span
              role="button"
              tabIndex="0"
              aria-labelledby="head-modal"
              onKeyPress={this.hideAlert}
              onClick={this.hideAlert}
              className="close"
              href=""
            />
          </div>
          <ScrollBar>
            <Audit
              onSuccess={msg => {
                this.msgSucesso(msg);
              }}
              oAudit={oAudit}
              closeModal={this.hideAlert}
            />
          </ScrollBar>
        </SweetAlert>
      )
    });
  };

  saveAudiCfinalnote = async AudiCfinalnote => {
    const { nAuditId } = this.state;

    this.setState({
      statusSave: '...'
    });

    if (this.timer) clearTimeout(this.timer);

    this.timer = setTimeout(
      function () {
        const { title } = document;
        document.title = intl.get('salvando');
        Axios.post(`audit/final-note/${nAuditId}`, {
          audi_cfinalnote: AudiCfinalnote
        }).then(() => {
          const { oAudit } = this.state;
          document.title = title;
          oAudit.audi_dfinalnoteincluded = moment.tz(moment(), 'America/Sao_Paulo').format('YYYY/MM/DD HH:mm:ss');
          this.setState({
            statusSave: '',
            oAudit
          });
        });
      }.bind(this),
      2000
    );
  };

  renderBreadcrumbs = () => {
    const { oAudit, aPages } = this.state;
    return (
      <div className="breadcrumbs-wrapper">
        <div className="inner-bread">
          <Breadcrumb bLastLink aPages={aPages} />
        </div>
        <span>{'>'}</span>
        <span>{oAudit?.norm?.norm_cinitials}</span>
      </div>
    );
  };

  checkIfUserIsResponsible = () => {
    const { oAudit } = this.state;
    if (!oAudit) return false;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    const aResponsibles = [...oAudit.audit_responsibles, ...oAudit.audit_auditors];

    return aResponsibles.some((oEntity) => oEntity.user && oEntity.user.user_nid === nUserId);
  };

  checkIfUserCanAccess = () => {
    const cModule = 'audit-management';
    return can('admin', cModule) || (can('access-auditmanagement', cModule) && this.checkIfUserIsResponsible());
  };

  checkIfUserCanCreateEdit = () => {
    const { oAudit } = this.state;
    const nUserId = Number(oLocalStorage.get('nUserId'));
    const cModule = 'audit-management';
    const aResponsibles = [...oAudit.audit_responsibles, ...oAudit.audit_auditors];

    return (
      can('admin', cModule) ||
      (can('create-edit-audit', cModule) &&
        aResponsibles.some((oUsrRes) => oUsrRes.user.user_nid === nUserId))
    );
  };

  renderRightMenu = () => {
    const { bActualMenuFolder, oAudit, bCanUserCreateEdit } = this.state;
    const { history } = this.props;
    return (
      <div className="right-menu">
        <span
          role="button"
          tabIndex="0"
          aria-labelledby="right-menu"
          onKeyPress={() => this.menuOpen()}
          onClick={() => this.menuOpen()}
          className="btn-menu"
        >
          <span />
        </span>

        <ul style={{ display: bActualMenuFolder ? 'block' : 'none' }} className="submenu">
          {bCanUserCreateEdit && (
            <li>
              <a
                onClick={e => {
                  this.editAudit(e, { ...oAudit, audit_requisites: oAudit.audit_requisites_evidences });
                  e.stopPropagation();
                }}
                href="#edit-norm"
              >
                {intl.get('editar', { tipo: 'Auditoria' })}
              </a>
            </li>
          )}

          <li>
            <a
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                history.push(`/report/audit/${oAudit.audi_nid}`);
              }}
              href="#report-audit"
              target="_blank"
            >
              {intl.get('AuditIndex.imprimir')}
            </a>
          </li>

          <li>
            <a
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                history.push(`/audit/interaction/${oAudit.audi_nid}`);
              }}
              target="_blank"
              href="#report-interactions"
            >
              {intl.get('AuditIndex.interacoes')}
            </a>
          </li>
          {bCanUserCreateEdit && (
            <li>
              <a
                onClick={e => {
                  this.showDeleteAudit(e, oAudit);
                  e.stopPropagation();
                }}
                style={{ color: '#c66' }}
                href="#delete-norm"
              >
                {intl.get('excluir', { tipo: 'Auditoria' })}
              </a>
            </li>
          )}
        </ul>
      </div>
    );
  };

  renderProgressBar = () => {
    const { oAudit } = this.state;
    return (
      <div className="containerBar">
        <div className="progressBar">
          <span style={{ width: `${oAudit.audi_nprogress}%` }} className="fill" />
        </div>
        <span className="percent">{`${oAudit.audi_nprogress}%`}</span>
      </div>
    );
  };

  generateCardHeader = () => {
    const { history } = this.props;

    const rcmpFilter = (
      <Filter
        fnOnType={this.handleChangeFilter}
        bIsOrderActive={false}
        rcmpRightMenu={this.renderRightMenu()}
        rcmpProgressBar={this.renderProgressBar()}
        history={history}
      />
    );

    const rcmpCardHeader = (
      <div ref={this.setWrapperRef}>
        <CardHeader rcmpFilter={rcmpFilter} bIsItToInsertGoBackBtn={false} />
      </div>
    );
    return rcmpCardHeader;
  };

  onChangeEditor = cContent => {
    const { bCanUserCreateEdit } = this.state;
    if (bCanUserCreateEdit) {
      this.saveAudiCfinalnote(cContent);
    }
  };

  editorInstance = oEditor => {
    this.setState({
      oEditor
    });
  };

  render() {
    const {
      aRequisites,
      aFiltredRequisites,
      bLoading,
      rcmpAlert,
      oAudit,
      rcmpAlertMsg,
      alert,
      nInitialRequisiteID,
      canUserAccess,
      bCanUserCreateEdit,
      cEditorContent,
      aAuditRequisites
    } = this.state;

    const { getAlert } = this.props;

    return (
      !bLoading && (
        <Page
          loading={bLoading ? 1 : 0}
          rcmpBreadcrumb={this.renderBreadcrumbs()}
          cTitle={oAudit?.norm?.norm_cinitials}
          cImage={managementImg}
          cDescription={
            oAudit.audi_cdescription
              ? fnDecodeHTMLEntities(oAudit.audi_cdescription.replace(/(&nbsp;|(<([^>]+)>))/gi, ''))
              : ''
          }
          rcmpCardHeader={canUserAccess ? this.generateCardHeader() : null}
          cCurrentSideMenuOption={intl.get('Nav.audit_management')}
          className="v2-audit-index"
        >
          <div className="alert-container">
            {rcmpAlertMsg}
            {rcmpAlert}
            {alert}
            {getAlert()}
            {!canUserAccess && (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {intl.get('sem_permissao')}
              </Alert>
            )}
          </div>

          {canUserAccess && (
            <>
              <div className="lastOption auditTable" id="table-emule">
                <div className="head-table">
                  <ul>
                    <li className="name">{intl.get('Norm.requisitos')}</li>
                    <li className="status">{intl.get('RequisiteIndex.status')}</li>
                    <li className="auditor">{intl.get('AuditIndex.auditor')}</li>
                    <li className="evidences">{intl.get('AuditIndex.evidencias')}</li>
                  </ul>
                </div>

                <hr />

                <div className="body-table">
                  <ul ref={el => (this.containerEl = el)}>
                    {(aFiltredRequisites.length ? aFiltredRequisites : aRequisites).map((oRequisite, i) => (
                      <li
                        itemID={oRequisite.req_nid}
                        className={`${oRequisite.open || this.expandInitialRequisiteParent(oRequisite) ? 'open' : ''
                          } ${this.getFeaturedClassName(oRequisite, aAuditRequisites)}`}
                        key={`req${oRequisite.req_nid}${i}`}
                        ref={oRequisite.req_nid === nInitialRequisiteID ? this.state.initialRequisiteRef : null}
                        role="button"
                        tabIndex="0"
                        onKeyPress={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                        onClick={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                      >
                        <div className={`row-table ${oRequisite.req_nid === nInitialRequisiteID ? 'focus' : ''}`}>
                          {this.renderRequisiteTitleDescription(oRequisite, 0)}
                          {oRequisite.req_etitletype != 'T' && (
                            <div className="optionsContainerTable">
                              <span
                                onClick={e => {
                                  e.stopPropagation();
                                  this.openContextMenu(e);
                                }}
                                className={`status insidespan status-${aAuditRequisites[oRequisite.req_nid].stat_nid}`}
                              >
                                {this.getStatusName(aAuditRequisites[oRequisite.req_nid].stat_nid) ?? ''}{' '}
                                {bCanUserCreateEdit && (
                                  <span>
                                    <i className="icon icon-next" />
                                  </span>
                                )}
                                {this.renderContextMenu(aAuditRequisites[oRequisite.req_nid])}
                              </span>
                              <span className="responsible">{aAuditRequisites[oRequisite.req_nid].user.user_cname}</span>
                              <span className="optionsHover mobile480-hide">
                                {this.renderRequisiteMenu(oRequisite)}
                              </span>
                            </div>
                          )}
                        </div>
                        {this.renderChilds(oRequisite, 1)}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div id="section-desc-audit-index">
                <p className="titlefinalnoteincluded">
                  {intl.get('AuditIndex.conclusao')} {this.state.statusSave}
                </p>
                {oAudit.user_final_note && oAudit.audi_dfinalnoteincluded && (
                  <p className="dfinalnoteincluded">
                    {intl.get('AuditIndex.ultima_edicao', {
                      cTime: moment
                        .parseZone(oAudit.audi_dfinalnoteincluded)
                        .tz('America/Sao_Paulo')
                        .format(`DD/MM/YYYY [${intl.get('AuditIndex.ultima_edicao_as')}] HH:mm`),
                      cAuditor: oAudit.user_final_note.user_cname
                    })}
                  </p>
                )}

                <div className="document-editor__toolbar requisite-editor" />

                <FroalaEditorComponent
                  bSimpleEditor
                  model={cEditorContent}
                  fnHandleChange={evtChange => this.onChangeEditor(evtChange)}
                  config={{
                    useClasses: true,
                    videoUploadURL: `${url.cBase}api/audit/upload`,
                    videoUploadParam: 'fVideo',
                    videoUploadParams: {
                      nAreaId: oAudit ? oAudit.audi_nid : '',
                      cArea: 'audit-index'
                    },
                    imageUploadURL: `${url.cBase}api/audit/upload`,
                    imageUploadParam: 'fImage',
                    imageUploadParams: {
                      nAreaId: oAudit.audi_nid,
                      cArea: 'audit-index'
                    },
                    requestHeaders: {
                      Authorization: `Bearer ${localStorage.getItem('cAccessToken')}`
                    },
                    fontSizeUnit: 'pt'
                  }}
                  fnRef={this.editorInstance}
                  disabled={!bCanUserCreateEdit}
                />
              </div>
            </>
          )}
        </Page>
      )
    );
  }
}

export default AuditIndex;
